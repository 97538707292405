import {advanceGet} from '../../requests'
import {metaphoricLightbulbAPIKey} from '../../credentials'
import {useEffect, useState} from 'react'
import Quiz from '../Quiz/Quiz'
import Loader from 'react-loader-spinner'
import './Main.css'

const Main = () => {
  const [loadedQuiz, setLoadedQuiz] = useState(null)
  const [loading, setLoading] = useState(false)

  const loadRandomQuiz = async () => {
    setLoading(true)
    const result = await advanceGet({
      location: `metaphoric-lightbulb/quiz-random`,
      extraHeaders: {symbolDoorApiKey: metaphoricLightbulbAPIKey}
    })
    if(result && result[0] && result[0].quizId) {
      setLoadedQuiz(result[0])
    }
    setLoading(false)
  }

  useEffect(
    () => {
      loadRandomQuiz()
    },
    []
  )

  return <div className={'main'}>
    {
      loading && <Loader type="ThreeDots" color={'white'}/>
    }
    <Quiz quizId={loadedQuiz?.quizId}/>
  </div>
}

export default Main