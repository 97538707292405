import React, {Component} from 'react'
import './legal.css'
import LegalLinks from "./LegalLinks"

export default class AcceptableUse extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		return (
			<div>
				<h1 className={'terms-and-conditions-title'}>Acceptable Use</h1>
				<LegalLinks />
				<div className={'legal-wrapper'}>
					{this.textBlock()}
				</div>
			</div>
		)
	}

	textBlock = () => <>
		<div>
			Last modified: May 30, 2020
		</div>

		<p>In order to maintain a secure and trusted platform, and to avoid the use of the Site for improper or unanticipated purposes, Waylist and all related and associated websites and applications (referred to here as Us, We or The Site) require you and all other users of the Site (referred to here as Users) to agree to and comply with these Terms of Use (Terms).</p>
		<h3>1. PROHIBITED SITE USE</h3>
		<p>You may not use the site to distribute material that may be harmful, offensive, or tasteless to others. You may not engage in the direct manipulation of the Site’s code, or making requests to it, other than via standard web browser navigation. You may not solicit that another user do anything potentially dangerous, tasteless, crass, or otherwise reprimandable. You may not use the site for any illegal purposes.</p>
	</>
}
