import React, {useState, useEffect} from 'react'
import AdvInput from '../AdvInput/AdvInput'
import {advanceGet, advancePost} from '../../requests'
import QuizPreview from '../QuizPreview/QuizPreview'
import {metaphoricLightbulbAPIKey} from '../../credentials'
import AdvButton from '../AdvButton/AdvButton'
import './Seach.css'
import {useNavigate} from 'react-router-dom'

const Search = (
	{
		handleCloseSearch=() => {}
	}={}
) => {
	handleCloseSearch = () => {
		setSearchTerms('')
	}
	const quizDifficulties = [
		{
			label: 'Easy',
			value: 'easy'
		},
		{
			label: 'Medium',
			value: 'medium'
		},
		{
			label: 'Hard',
			value: 'hard'
		}
]

	const navigate = useNavigate()

	const [debouncedSearchTerms, setDebouncedSearchTerms] = useState('')
	const [searchTerms, setSearchTerms] = useState('')
	const [searchResults, setSearchResults] = useState([])
	const [isSearching, setIsSearching] = useState(false)
	const [performSearchTimeout, setPerformSearchTimeout] = useState(null)
	const [quizDifficulty, setQuizDifficulty] = useState(quizDifficulties[0])
	const [isGenerating, setIsGenerating] = useState(false)

	const getSearchTerms = () => {
		return searchTerms ? encodeURIComponent(searchTerms) : ''
	}

	const doSearch = async () => {
		setIsSearching(true)
		const results = await advanceGet({
			location: `metaphoric-lightbulb/search?terms=${getSearchTerms()}`,
			extraHeaders: {symbolDoorApiKey: metaphoricLightbulbAPIKey}
		})
		setSearchResults(results)
		setIsSearching(false)
	}

	useEffect(
		() => {
			if (performSearchTimeout) {
				clearTimeout(performSearchTimeout)
			}
			const searchTimeout = setTimeout(() => setDebouncedSearchTerms(searchTerms), 400)
			setPerformSearchTimeout(searchTimeout)
		},
		[searchTerms]
	)

	useEffect(
		() => {
			doSearch()
		},
		[debouncedSearchTerms]
	)

	const handleGeneration = async (e) => {
		e.stopPropagation()
		setIsGenerating(true)
		const result = await advancePost({
			parameters: {
				topic: searchTerms,
				difficulty: quizDifficulty.value
			},
			location: `metaphoric-lightbulb/generateMultipleChoiceQuiz`,
			extraHeaders: {symbolDoorApiKey: metaphoricLightbulbAPIKey}
		})
		if(result) {
			setIsGenerating(false)
			navigate(`/q/${result?.quizId}`)
		}
	}

	const rotateQuizDifficulty = (e) => {
		e.stopPropagation()
		if(quizDifficulty.value === quizDifficulties[0].value) {
			setQuizDifficulty(quizDifficulties[1])
		}
		else if(quizDifficulty.value === quizDifficulties[1].value) {
			setQuizDifficulty(quizDifficulties[2])
		}
		else if(quizDifficulty.value === quizDifficulties[2].value) {
			setQuizDifficulty(quizDifficulties[0])
		}
	}

	const renderMain = () => {
		return (
			<div className={'search'}>
				<AdvInput
					loading={isSearching}
					value={searchTerms}
					onChange={(e) => setSearchTerms(e.target.value)}
					placeholder="Quiz search and creation"
				/>
				{
					(searchResults && searchTerms) && <div className={'search-results'} onClick={handleCloseSearch}>

						{
							(Array.isArray(searchResults) && searchResults.length > 0) &&
							<div className={'search-results__label'}>
								Quizzes
							</div>
						}
							{
								searchResults && searchResults.map(
									(result, i) => <div onClick={() => {navigate(`/q/${result.quizId}`)}} key={`quiz-result-${i}`} className={'search-result'}>
										<QuizPreview
											listId={result.id}
											inline
											quizData={result}
										/>
									</div>
								)
							}
						<div className={'search-results__label'}>
							Create a Quiz About: "{searchTerms}"
						</div>
						<AdvButton
							onClick={isGenerating ? () => {} : rotateQuizDifficulty}
							extendClassNameString={'select-quiz-difficulty'}
							text={`Difficulty: ${quizDifficulty?.label}`}
							fullWidth
						/>
						<AdvButton
							onClick={handleGeneration}
							extendClassNameString={isGenerating ? 'generate-quiz generate-quiz__is-generating' : 'generate-quiz'}
							fullWidth
							text={isGenerating ? 'Generating - please wait!' : 'Generate'}
							loading={isGenerating}
						/>
						<div className={'search-results__gen-info'}>
							Generation time expected: 2mins
						</div>
					</div>
				}

			</div>
		)
	}

	return renderMain()
}

export default Search
