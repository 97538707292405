import React, {Component} from 'react'
import './legal.css'
import LegalLinks from "./LegalLinks"
import {tr} from "../../../functions/translations/translations"

export default class TermsAndConditions extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		document.title = tr(`Legal - Metaphoric Lightbulb`)
		return (
			<div>
				<h1 className={`terms-and-conditions-title`}>Terms and Conditions</h1>
				<LegalLinks />
				<div className={'legal-wrapper'}>
					{this.textBlock()}
				</div>
			</div>
		)
	}

	textBlock = () => <>
		<div>
			Last modified: May 30, 2020
		</div>
		<h3>1.   Agreement to Terms</h3>
		<p>1.1  These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (you), and Sapiens Creations, doing business as Metaphoric Lightbulb, located at Mullagh, Co. Clare, Ireland (we, us), concerning your access to and use of the Metaphoric Lightbulb (metaphoriclightbulb.org) website, all affiliated websites and applications, including mobile websites and applications, owned and operated by us, our predecessors or successors in interest, or our Affiliates (collectively, the “Site”).</p>

		<p>The Site provides the following services: Metaphoric Lightbulb promotes brand-user interaction, social interaction and motivation via the creation of fun and self-improving challenges. Metaphoric Lightbulb does this is by facilitating the creation of challenges templates. Customers can then use Metaphoric Lightbulb to set themselves or others a challenge based on these challenge templates. The user creating a challenge in this way agrees to these terms and enters into an escrow agreement between themselves and any challenge participants that may take part in the challenge. Participants are those users of Metaphoric Lightbulb who receive a challenge invite and use Metaphoric Lightbulb to participate in a challenge. Metaphoric Lightbulb mediates this escrow agreement to see that, where it is fulfilled, the conditions of the said escrow agreement are carried out. Metaphoric Lightbulb's software allows individuals and brands to easily create challenge templates, for challengers to designate prizes for participation (held in escrow by Metaphoric Lightbulb), and display the challenges online. Metaphoric Lightbulb makes participants’ responses to these challenges, as well as other participant data, available to the challenge creators. You agree that by accessing the Site and/or Services, you have read, understood, and agree to be bound by all of these and the following Terms and Conditions.</p>
		<p>If you do not agree with all of these Terms and Conditions, then you are prohibited from using the Site and Services and you must discontinue use immediately. We recommend that you print a copy of these Terms and Conditions for future reference. By continuing to use the site after your being made aware of these Terms and Conditions, you express your agreement to all of the Terms and Conditions set out in this document and the documents concerning Metaphoric Lightbulb that it makes reference to.</p>
		<p>1.2  The supplemental policies set out in Section 1.7 below, as well as any supplemental terms and condition or documents that may be posted on the Site from time to time, are expressly incorporated by reference.</p>
		<p>1.3  We may make changes to these Terms and Conditions at any time. The updated version of these Terms and Conditions will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. You are responsible for reviewing these Terms and Conditions to stay informed of updates. Your continued use of the Site represents that you have accepted such changes.</p>
		<p>1.4  We may update or change the Site from time to time to reflect changes to our products, our users' needs and/or our business priorities.</p>
		<p>1.5  Our site is directed to people residing in all jurisdictions and countries, worldwide. However, the information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.</p>
		<p>1.6  The Site is intended for users who are at least 18 years old.  If you are under the age of 18, you are not permitted to register for the Site or use the Services without parental permission.</p>
		<p>1.7  Additional policies which also apply to your use of the Site include:</p>
		<li>Our Privacy Notice metaphoriclightbulb.org/privacy-policy, which sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using the Site, you consent to such processing and you warrant that all data provided by you is accurate.</li>
		<li>Our Acceptable Use Policy metaphoriclightbulb.org/acceptable-use, which sets out the permitted uses and prohibited uses of the Site. When using the Site, you must comply with this Acceptable Use Policy.</li>
		<li>If you purchase digital goods, services, software, and/or content from the Site, our terms and conditions of supply metaphoriclightbulb.org/terms-and-conditions-of-supply will apply to the sales, use, and licence.</li>
		<li>Certain parts of this Site can be used only on payment of a fee. If you wish to use such Services, you will, in addition to our Terms and Conditions, also be subject to our Terms and conditions of supply metaphoriclightbulb.org/terms-and-conditions-of-supply.</li>

		<h3>2. Acceptable Use</h3>

		<p>2.1  Our full Acceptable Use Policy metaphoriclightbulb.org/acceptable-use, sets out all the permitted uses and prohibited uses of this site.</p>
		<p>2.2  You may not access or use the Site for any purpose other than that for which we make the site and our services available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.</p>
		<p>2.3  As a user of this Site, you agree not to:</p>
		<li>Systematically retrieve data or other content from the Site to a compile database or directory without written permission from us</li>
		<li>Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users to send unsolicited email or creating user accounts under false pretenses</li>
		<li>Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any content or enforce limitations on the use</li>
		<li>Engage in unauthorized framing of or linking to the Site</li>
		<li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords</li>
		<li>Make improper use of our support services, or submit false reports of abuse or misconduct</li>
		<li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools</li>
		<li>Interfere with, disrupt, or create an undue burden on the Site or the networks and services connected to the Site</li>
		<li>Attempt to impersonate another user or person, or use the username of another user</li>
		<li>Use the Site or our content as part of any effort to compete with us or to create a revenue-generating endeavor or commercial enterprise</li>
		<li>Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site</li>
		<li>Attempt to access any portions of the Site that you are restricted from accessing</li>
		<li>Harass, annoy, intimidate, or threaten any of our employees, agents, or other users</li>
		<li>Delete the copyright or other proprietary rights notice from any of the content</li>
		<li>Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code</li>
		<li>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material that interferes with any party’s uninterrupted use and enjoyment of the Site, or any material that acts as a passive or active information collection or transmission mechanism</li>
		<li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site</li>
		<li>Use the Site in a manner inconsistent with any applicable laws or regulations</li>
		<li>Threaten users with negative feedback or offering services solely to give positive feedback to users</li>
		<li>Misrepresent experience, skills, or information about a User</li>
		<li>Advertise products or services not intended by us</li>
		<li>Falsely imply a relationship with us or another company with whom you do not have a relationship</li>

		<h3>3.   Information you provide to us</h3>
		<p>3.1  You represent and warrant that: (a) all registration information you submit will be true, accurate, current, and complete and relate to you and not a third party; (b) you will maintain the accuracy of such information and promptly update such information as necessary; (c) you will keep your password confidential and will be responsible for all use of your password and account; (d) you have the legal capacity and you agree to comply with these Terms and Conditions; and (e) you are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use the Site.</p>
		<p>If you know or suspect that anyone other than you knows your user information (such as an identification code or user name) and/or password you must promptly notify us at info@metaphoriclightbulb.org.</p>
		<p>3.2  If you provide any information that is untrue, inaccurate, not current or incomplete, we may suspend or terminate your account. We may remove or change a user name you select if we determine that such user name is inappropriate.</p>
		<p>3.3  As part of the functionality of the Site, you may link your account with online accounts you may have with third party service providers (each such account, a Third Party Account) by either: (a) providing your Third Party Account login information through the Site; or (b) allowing us to access your Third Party Account, as is permitted under the applicable terms and conditions that govern your use of each Third Party Account.</p>
		<p>You represent that you are entitled to disclose your Third Party Account login information to us and/or grant us access to your Third Party Account without breach by you of any of the terms and conditions that govern your use of the applicable Third Party Account and without obligating us to pay any fees or making us subject to any usage limitations imposed by such third party service providers.</p>
		<p>3.4  By granting us access to any Third Party Accounts, you understand that (a) we may access, make available and store (if applicable) any content that you have provided to and stored in your Third Party Account (the “Social Network Content”) so that it is available on and through the Site via your account, including without limitation any friend lists;  and (b) we may submit and receive additional information to your Third Party Account to the extent you are notified when you link your account with the Third Party Account.</p>
		<p>Depending on the Third Party Accounts you choose and subject to the privacy settings that you have set in such Third Party Accounts, personally identifiable information that you post to your Third Party Accounts may be available on and through your account on the Site. Please note that if a Third Party Account or associated service becomes unavailable or our access to such Third Party Account is terminated by the third party service provider, then Social Network Content may no longer be available on and through the Site.</p>
		<p>You will have the ability to disable the connection between your account on the Site and your Third Party Accounts at any time. Please note that your relationship with the third party service providers associated with your third party accounts is governed solely by your agreement(s) with such third party service providers.  We make no effort to review any Social Network Content for any purpose, including but not limited to, for accuracy, legality or non-infringement, and we are not responsible for any Social Network Content.</p>
		<p>You acknowledge and agree that we may access your email address book associated with a Third Party Account and your contacts list stored on your mobile device or tablet computer solely for purposes of identifying and informing you of those contacts who have also registered to use the Site. At your email request to info@metaphoriclightbulb.org or through your account settings (if applicable), we will deactivate the connection between the Site and your Third Party Account and attempt to delete any information stored on our servers that was obtained through such Third Party Account, except the username and profile picture that became associated with your account.</p>
		<h3>4.    Content you provide to us</h3>
		<p>4.1  There may be opportunities for you to post content to the Site or send feedback to us (User Content). You understand and agree that your User Content may be viewed by other users on the Site, and that they may be able to see who has posted that User Content.</p>
		<p>4.2  You further agree that we can use your User Content for any other purposes whatsoever in perpetuity without payment to you, and combine your User Content with other content for use within the Site and otherwise, and modify your User Content in any way for use within the Site and otherwise, and transmit your User Content in any way, to any third party for unlimited use by them under the same terms of modifiability and combinability. We do not have to attribute your User Content to you. When you upload or post content to our site, you grant us the following rights and permissions to use that content:</p>
		<p>Licenses Granted to Other Users, with permission from Us:</p>
		<p>Length of time: In perpetuity; Purpose: For any use</p>

		<p>When you post User Content on the Site or through the Site Services or provide Us with User Content, you represent and warrant that you have the right, power, and authority to post that User Content and grant the licenses specified below.  You further represent and warrant that by posting or providing such User Content you will not violate third-party rights of any kind, including, without limitation, any Intellectual Property Rights, rights of publicity, and privacy rights.  To the extent your User Content may be copyrightable, you represent, warrant, and covenant that you are the owner of all the copyright rights to such User Content and that Us may exercise the rights to your User Content granted under the Terms of Service without any liability or obligation for any payment.
			You retain all ownership rights in any User Content you post on Us.  To the extent permitted by applicable law, you also grant to Us and our successors and Affiliates a royalty-free, sub-licensable, transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, reproduce, modify, publish, list information regarding, edit, translate, distribute, publicly perform, publicly display, and make derivative works of all such User Content and your name, voice, and/or likeness as contained in your User Content, in whole or in part, and in any form, media, or technology, whether now known or hereafter developed, for use in connection with the Site and Us’s (and our successors’ and Affiliates’) business, including, without limitation, for promoting and redistributing part or all of the Site (and derivative works thereof) in any media formats and through any media channels.  You also hereby grant each User a non-exclusive license to access your User Content through the Site and to use, reproduce, distribute, display, and perform such User Content to the extent permitted through the normal functionality of the Site and subject to all applicable confidentiality and other provisions of this Agreement, our Privacy Policy, and applicable law.</p>


		<p>4.3  In posting User Content, including reviews or making contact with other users of the Site you shall comply with our Acceptable Use Policy metaphoriclightbulb.org/acceptable-use.</p>
		<p>4.4  You warrant that any User Content does comply with our Acceptable Use Policy, and you will be liable to us and indemnify us for any breach of that warranty. This means you will be responsible for any loss or damage we suffer as a result of your breach of this warranty.</p>
		<p>4.5  We have the right to remove any User Content you put on the Site if, in our opinion, such User Content does not comply with the Acceptable Use Policy.</p>
		<p>4.6  We are not responsible and accept no liability for any User Content including any such content that contains incorrect information or is defamatory or loss of User Content. We accept no obligation to screen, edit or monitor any User Content but we reserve the right to remove, screen and/or edit any User Content without notice and at any time. User Content has not been verified or approved by us and the views expressed by other users on the Site do not represent our views or values.</p>
		<p>4.7  If you wish to complain about User Content uploaded by other users please contact us at info@metaphoriclightbulb.org.</p>
		<h3>5. Our content</h3>
		<p>5.1  Unless otherwise indicated, the Site and Services including source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (Our Content) are owned or licensed to us, and are protected by copyright and trade mark laws.</p>
		<p>5.2  Except as expressly provided in these Terms and Conditions, no part of the Site, Services or Our Content may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.</p>
		<p>5.3  Provided that you are eligible to use the Site, you are granted a limited licence to access and use the Site and Our Content and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use.</p>
		<p>5.4  You shall not (a) try to gain unauthorised access to the Site or any networks, servers or computer systems connected to the Site; and/or (b) make for any purpose including error correction, any modifications, adaptions, additions or enhancements to the Site or Our Content, including the modification of the paper or digital copies you may have downloaded.</p>
		<p>5.5  We shall (a) prepare the Site and Our Content with reasonable skill and care; and (b) use industry standard virus detection software to try to block the uploading of content to the Site that contains viruses.</p>
		<p>5.6  The content on the Site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from taking, any action on the basis of the content on the Site.</p>
		<p>5.7  Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that Our Content on the Site is accurate, complete or up to date.</p>
		<h3>6.   Link to third party content</h3>
		<p>6.1  The Site may contain links to websites or applications operated by third parties. We do not have any influence or control over any such third party websites or applications or the third party operator. We are not responsible for and do not endorse any third party websites or applications or their availability or content.</p>
		<p>6.2  We accept no responsibility for adverts contained within the Site. If you agree to purchase goods and/or services from any third party who advertises in the Site, you do so at your own risk. The advertiser, and not us, is responsible for such goods and/or services and if you have any questions or complaints in relation to them, you should contact the advertiser.</p>
		<h3>7. Site Management</h3>
		<p>7.1  We reserve the right at our sole discretion, to (1) monitor the Site for breaches of these Terms and Conditions; (2) take appropriate legal action against anyone in breach of applicable laws or these Terms and Conditions; (3) refuse, restrict access to or availability of, or disable (to the extent technologically feasible) any of your Contributions; (4) remove from the Site or otherwise disable all files and content that are excessive in size or are in any way a burden to our systems; and (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site and Services.</p>
		<p>7.2  We do not guarantee that the Site will be secure or free from bugs or viruses.</p>
		<p>7.3  You are responsible for configuring your information technology, computer programs and platform to access the Site and you should use your own virus protection software.</p>
		<h3>8. Modifications to and availability of the Site</h3>
		<p>8.1  We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. We also reserve the right to modify or discontinue all or part of the Services without notice at any time.</p>
		<p>8.2  We cannot guarantee the Site and Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site or Services during any downtime or discontinuance of the Site or Services.We are not obliged to maintain and support the Site or Services or to supply any corrections, updates, or releases.</p>
		<p>8.3  There may be information on the Site that contains typographical errors, inaccuracies, or omissions that may relate to the Services, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information at any time, without prior notice.</p>
		<h3>9. Disclaimer/Limitation of Liability</h3>
		<p>9.1  The Site and Services are provided on an as-is and as-available basis. You agree that your use of the Site and/or Services will be at your sole risk except as expressly set out in these Terms and Conditions. All warranties, terms, conditions and undertakings, express or implied (including by statute, custom or usage, a course of dealing, or common law) in connection with the Site and Services and your use thereof including, without limitation, the implied warranties of satisfactory quality, fitness for a particular purpose and non-infringement are excluded to the fullest extent permitted by applicable law.</p>
		<p>We make no warranties or representations about the accuracy or completeness of the Site’s content and are not liable for any (1) errors or omissions in content: (2) any unauthorized access to or use of our servers and/or any and all personal information and/or financial information stored on our server; (3) any interruption or cessation of transmission to or from the site or services; and/or (4) any bugs, viruses, trojan horses, or the like which may be transmitted to or through the site by any third party. We will not be responsible for any delay or failure to comply with our obligations under these Terms and Conditions if such delay or failure is caused by an event beyond our reasonable control.</p>
		<p>9.2  Our responsibility for loss or damage suffered by you:</p>
		<p>Whether you are a consumer or a business user:</p>
		<li>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.</li>
		<li>If we fail to comply with these Terms and Conditions, we will be responsible for loss or damage you suffer that is a foreseeable result of our breach of these Terms and Conditions, but we would not be responsible for any loss or damage that were not foreseeable at the time you started using the Site/Services.</li>
		<li>Notwithstanding anything to the contrary contained in the Disclaimer/Limitation of Liability section, our liability to you for any cause whatsoever and regardless of the form of the action, will at all times be limited to a total aggregate amount equal to the amount paid, if any, by you to us for the Services/Site during the six (6) month period prior to any cause of action arising. Different limitations and exclusions of liability may apply to liability arising as a result of the supply of any products to you, which will be set out in our Terms and conditions of supply metaphoriclightbulb.org/terms-and-conditions-of-supply.</li>
		<p>If you are a business user:</p>
		<p>We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</p>
		<li>use of, or inability to use, our Site/Services; or</li>
		<li>use of or reliance on any content displayed on our Site.</li>
		<p>In particular, we will not be liable for:</p>
		<li>loss of profits, sales, business, or revenue</li>
		<li>business interruption</li>
		<li>loss of anticipated savings</li>
		<li>loss of business opportunity, goodwill or reputation, or</li>
		<li>any indirect or consequential loss or damage.</li>
		If you are a consumer user:
		<li>Please note that we only provide our Site for domestic and private use. You agree not to use our Site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</li>
		<li>If defective digital content that we have supplied, damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation.</li>
		<li>You have legal rights in relation to goods that are faulty or not as described. Advice about your legal rights is available from your local Citizens' Advice Bureau or Trading Standards office. Nothing in these Terms and Conditions will affect these legal rights.</li>
		<h3>10. Term and Termination</h3>
		<p>10.1  These Terms and Conditions shall remain in full force and effect while you use the Site or Services or are otherwise a user of the Site, as applicable. You may terminate your use or participation at any time, for any reason, by following the instructions for terminating user accounts in your account settings, if available, or by contacting us at info@metaphoriclightbulb.org.</p>
		<p>10.2  Without limiting any other provision of these Terms and Conditions, we reserve the right to, in our sole discretion and without notice or liability, deny access to and use of the Site and the Services (including blocking certain IP addresses), to any person for any reason including without limitation for breach of any representation, warranty or covenant contained in these Terms and Conditions or of any applicable law or regulation.</p>
		<p>If we determine, in our sole discretion, that your use of the Site/Services is in breach of these Terms and Conditions or of any applicable law or regulation, we may terminate your use or participation in the Site and the Services or delete your profile and any content or information that you posted at any time, without warning, in our sole discretion.</p>
		<p>10.3  If we terminate or suspend your account for any reason set out in this Section 9, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>
		<h3>11. Mobile Application</h3>
		<p>11.1  If you access the Services via a mobile application, then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the mobile application on wireless electronic devices owned or controlled by you, and to access and use the mobile application on such devices strictly in accordance with the terms and conditions of this license.</p>
		<p>11.2  For consumers only  - If you are a customer in the European Union, you have certain rights to decompile the Software if:</p>
		<li>this is necessary to obtain the information that you need to make the software interoperable with other software and</li>
		<li>we have not made that information available to you.</li>
		<p>Before reverse engineering or decompiling the software, you must first write to us and ask us to provide you with the interoperability information that you need. Please provide us with full details of your requirements so that we can assess what information you need. We may impose reasonable conditions on providing you with interoperability information. You must use that information only for the purpose of making the software interoperable with other software. You must not use that information for any other purpose.</p>
		<p>11.3  You will not:</p>
		<p>(a) reverse engineer, decompile or otherwise try to discover the source code of the software/application unless you have first written to us requesting interoperability information and we have failed to provide you with that information or if we have failed to offer to provide you with interoperability information on reasonable conditions";</p>
		<p>(b) make any modification, adaptation, improvement, enhancement, translation or derivative work from the application;</p>
		<p>(c) breach any applicable laws, rules or regulations in connection with your access or use of the application;</p>
		<p>(d) remove, alter or obscure any proprietary notice (including any notice of copyright or trade mark) posted by us or the licensors of the application;</p>
		<p>(e) use the application for any revenue generating endeavor, commercial enterprise, or other purpose for which it is not designed or intended;</p>
		<p>(f) make the application available over a network or other environment permitting access or use by multiple devices or users at the same time;</p>
		<p>(g) use the application for creating a product, service or software that is, directly or indirectly, competitive with or in any way a substitute for the application;</p>
		<p>(h) use the application to send automated queries to any website or to send any unsolicited commercial e-mail; or</p>
		<p>(i) use any proprietary information or any of our interfaces or our other intellectual property in the design, development, manufacture, licensing or distribution of any applications, accessories or devices for use with the application.</p>
		<p>11.4  The following terms apply when you use a mobile application obtained from either the Apple Store or Google Play (each an App Distributor) to access the Services:</p>
		<p>(a) The licence granted to you for our mobile application is limited to a non-transferable licence to use the application on a device that utilizes the Apple iOS or Android operating system, as applicable, and in accordance with the usage rules set forth in the applicable App Distributor terms of service;</p>
		<p>(b) We are responsible for providing any maintenance and support services with respect to the mobile application as specified in these Terms and Conditions or as otherwise required under applicable law. You acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance and support services with respect to the mobile application;</p>
		<p>(c) In the event of any failure of the mobile application to conform to any applicable warranty, you may notify an App Distributor, and the App Distributor, in accordance with its terms and policies, may refund the purchase price, if any, paid for the mobile application, and to the maximum extent permitted by applicable law, an App Distributor will have no other warranty obligation whatsoever with respect to the mobile application;</p>
		<p>(d) You represent and warrant that (i) you are not located in a country that is subject to a U.S. government embargo, or that has been designated by the U.S. government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. government list of prohibited or restricted parties;</p>
		<p>(e) You must comply with applicable third party terms of agreement when using the mobile application, e.g., if you have a VoIP application, then you must not be in breach of their wireless data service agreement when using the mobile application; and</p>
		<p>(f) You acknowledge and agree that the App Distributors are third party beneficiaries of these Terms and Conditions, and that each App Distributor will have the right (and will be deemed to have accepted the right) to enforce these Terms and Conditions against you as a third party beneficiary thereof.</p>

		<h2>12.1. Terms of Site Use, Partner Products, and Challenge Creation, Reward, Participation, and Escrow Contracts</h2>
		<h3>12.1. Terms of Site Use, Partner Products, and Challenge Creation, Reward, Participation, and Escrow Contracts</h3>
		<p>12.1.1  When you set a challenge and when you participate in a challenge, you enter into a contract and agree to act as a challenger and/or participant escrow account user as defined in the section 12.2.</p>
		<p>12.1.2  Fees paid to us to start, create, enter into, preside over or otherwise participate in escrow agreements are non-refundable to the extent permitted by applicable law.</p>
		<p>12.1.3  We agree to make every effort we deem reasonable to transfer challenge prizes to challenge winners in a prompt, transparent manner. If, for whatever reason, the rare circumstance arises where this proves to be extraordinarily difficult or complicated, we will return the initial amount paid by the challenge creator to create the challenge (less fees) to the challenge creator. For example, if the service that we use to make payments is not accessible to the successful challenge participant, or vice versa, the course of action of returning the corresponding amount to the challenge creator will be pursued by us.</p>
		<p>12.1.4  A challenger wishing to cancel a challenge must contact us via one of the methods enumerated on our site. If we agree to cancel the challenge, the challenger will be returned the amount they paid to create the challenge, less fees. We may not agree to cancel the challenge in certain circumstances. These circumstances may include, but are not limited to, the challenge participants already having invested time and effort in the challenge, the challenge having been active for a large period of time before requests to cancel the challenge come to light, the challenge having been too difficult to complete, other challengers having already paid entry fees to participate in the challenge. Whether we cancel a challenge in this manner is at our sole discretion.</p>
		<p>12.1.5  You may not use our services to participate in Gambling.</p>
		<p>12.1.6  Partner products are products and their owners that we, at our discretion, allow to integrate with our services and Application Programming Interface (API) and therewith create and list challenges and challenge templates. Partner products may be removed from our platform at any time, at our discretion. We reserve the right to do this, and will exert it, especially but not limited to when: partner products are slow to reply to our correspondences; partner products are unclear in their communications, as we deem unclarity; partner products do not maintain their quality, as we perceive it, to our standards; our goals change, and we no longer wish to partner with a particular product; partner products fail to correctly validate challenges; partner products encounter too much difficulty using our API.</p>
		<p>12.1.7  It may be the case that partner products enter into a written agreement with ourselves whereby we decide to pay out to them an amount related to challenge fees and/or challenge amounts concerning their product on a regular basis. We specifically reserve the right to terminate this arrangement at our discretion, where the partner product in question does not meet our standards.</p>
		<p>12.1.8  We foresee that some challenges (escrow agreements) may be created (entered into) that do not enjoy any participation or winners before their conclusion, or do not enjoy participation up to the number of winning places specified during challenge creation. Where these challenges involved the payment of a fiat currency prize and fee to be created, we will resolve to return to the challenge creator the amount paid for challenge creation, less any amounts reserved for rewarding successful active participants, less fees. However, we will partake in this action at our own discretion, and cases such as but not limited to that stated in section 12.1.9 will be exempted from this course of action.</p>
		<p>12.1.9  Where a user takes part in a challenge (escrow agreement) where they challenge themselves, the user is deemed to be acting as both the challenger and the participant. Furthermore, where that challenge is not successfully completed by the participant, it is a condition of the agreement that Metaphoric Lightbulb retains all challenge amounts and fees. The purpose of these types of challenges is to create motivation to complete the challenge in the user, and that is why this condition is present. Metaphoric Lightbulb resolves to see that a portion of these said retentions reach a recognized charity of our selection.</p>

		<p>12.2.1  All users who use Metaphoric Lightbulb to set challenges (challengers) agree to enter into an escrow contract and agreement with any participants who accept (actively participate in) their challenges.</p>
		<p>12.2.2  This escrow contract is mediated by Metaphoric Lightbulb. For the mediation of this escrow contract, Metaphoric Lightbulb may charge the challenger a fee. When charged, this fee is paid in advance, during challenge creation, and is non-refundable to the extent permitted by applicable law.</p>
		<p>12.2.3  All such contracts entered into between the listed parties are formal arrangements active for Metaphoric Lightbulb and the challenger from the moment of challenge creation, and for participants from the moment that they begin participating in a challenge by using Metaphoric Lightbulb and its services.</p>
		<p>12.2.4  All such contracts entered into between the listed parties follow the same format: Where a prize is set by the challenge creator for the successful challenge participant(s), Metaphoric Lightbulb will hold that prize until the challenge has concluded. A challenge is concluded when the term set out during challenge creation is reached or where a certain condition set during challenge creation is met. If, at such a time, one or more participants have succeeded in completing the terms of the agreement, Metaphoric Lightbulb will release the prize amount to the challenge winner on behalf of the challenger. Where more than one challenge participant has fulfilled the terms of the escrow agreement by the time the challenge concludes, the first participants to have fulfilled the terms is/are deemed to be the challenge winner(s). The terms of the escrow agreement are nuanced for each challenge template and challenge. During challenge creation, a challenger takes the base of the escrow agreement's terms from the challenge template description, and adds their own customizations to them in the process of challenge creation. All of these (the base terms set out in the challenge template, and the customizations set out by the challenger) are visible to the participant accepting and participating in the said challenge. Rules set out by Metaphoric Lightbulb and legal restrictions of the regions and territories of all parties supersede these terms, with the laws of Ireland superseding all terms in the case of any clash. Metaphoric Lightbulb, as the mediator of the escrow agreement, from the moment that a challenge is started by the challenger, has the sole discretion over when and to whom funds will be released.</p>
		<p>12.2.5  Further base conditions of escrow agreements (challenges) are outlined in section 12.1</p>
		<p>12.2.6  Users agree that they will not receive interest or other earnings on the funds held by Metaphoric Lightbulb in escrow.</p>
		<p>12.2.7  Users agree that any monies held in escrow by Metaphoric Lightbulb may be held indefinitely or retained permanently by us if you do not adhere to these terms.</p>
		<p>12.2.8  If a dispute should arise between challengers and participants in an escrow agreement, Metaphoric Lightbulb shall endeavour to mediate the dispute, and ultimately has the last word on resolving the escrow agreement.</p>
		<p>12.2.9  Challenge prizes are paid out in the form of Reward Funds. Reward Funds are an amount of USD associated with the user's account. If any conversion to and from currencies is necessary, Metaphoric Lightbulb uses the latest exchange rates. Metaphoric Lightbulb will update exchange rates daily, based on the best external references. Metaphoric Lightbulb does not set its own exchange rates. A user can request a withdrawal of Reward Funds at any time. We reserve the right to impose a minimum withdrawal amount of Reward Funds (USD). We reserve the right to charge a fee for processing withdrawals of these Reward Funds. If a user's account shows no activity, as measured by no change to the amount of Reward Funds held by Metaphoric Lightbulb, for a period of 365 days, the user agrees to forfeit their Reward Funds to Metaphoric Lightbulb. Metaphoric Lightbulb will endeavour to contact users who have more than 20 USD of Reward Funds before processing this forfeit. If the user contacted (via the contact details given to Metaphoric Lightbulb during sign-up) does not reply for a further grace period of 30 days, Metaphoric Lightbulb will continue with claiming the Reward Funds forfeit. Metaphoric Lightbulb reserves the right to use these forfeit and claimed Reward Funds for any purpose, including but not limited to: Charitable donation, site maintenance, marketing, and setting Metaphoric Lightbulb-hosted challenges.</p>
		<p>12.2.10  Users will each (1) create and maintain records to document satisfaction of their respective obligations under this agreement; any service contract, including, without limitation, their respective payment obligations and compliance with tax and employment laws; and any Metaphoric Lightbulb agreement, and (2) provide copies of such records to Metaphoric Lightbulb upon request.  Nothing in this subsection requires or will be construed as requiring Metaphoric Lightbulb to supervise or monitor a User’s compliance with this agreement, the other terms of service, or a service contract.</p>
		<p>12.2.11  You hereby authorize and instruct Metaphoric Lightbulb to act as an escrow agent in connection with any challenges set or participated in by you.</p>

		<h2>12.3. Formal Invoices and Taxes</h2>
		<p>12.3.1  Metaphoric Lightbulb will have no responsibility for determining the necessity of or for issuing any formal invoices, or for determining, remitting, or withholding any taxes applicable to the Service Fees.  Users will be solely responsible for determining whether it is required by applicable law to issue any formal invoices for the Challenge Creation and Escrow Fees and for issuing any invoices so required.  Challenge Participants will also be solely responsible for determining whether: (a) Challenge Participants or Metaphoric Lightbulb is required by applicable law to remit to the appropriate authorities any value added tax or any other taxes or similar charges applicable to the Service Fees and remitting any such taxes or charges to the appropriate authorities on behalf of itself or Metaphoric Lightbulb, as appropriate; and (b) Metaphoric Lightbulb is required by applicable law to withhold any amount of the Challenge Creation and Escrow Fees and for notifying Metaphoric Lightbulb of any such requirement and indemnifying Metaphoric Lightbulb (either by Waylist, at our sole discretion, offsetting the relevant amount against a future payment of Challenge Creation and Escrow Fees to Challenge Participants or Challenge Participants reimbursing Waylist for the applicable amount) for any requirement to pay any withholding amount to the appropriate authorities (including penalties and interest).  In the event of an audit of Waylist, both Challenge Participants and Challengers agree to promptly cooperate with Waylist and provide copies of their tax returns and other documents as may be reasonably requested for purposes of such audit, including but not limited to records showing Challenge Acceptor and Challenger are engaging in an independent business as represented to Waylist.</p>

		<h3>13. General</h3>
		<p>13.1  Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing.</p>
		<p>You hereby agree to the use of electronic signatures, contracts, orders and other records and to electronic delivery of notices, policies and records of transactions initiated or completed by us or via the Site. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by other than electronic means.</p>
		<p>13.2  These Terms and Conditions and any policies or operating rules posted by us on the Site or in respect to the Services constitute the entire agreement and understanding between you and us.</p>
		<p>13.3  Our failure to exercise or enforce any right or provision of these Terms and Conditions shall not operate as a waiver of such right or provision.</p>
		<p>13.4  We may assign any or all of our rights and obligations to others at any time.</p>
		<p>13.5  We shall not be responsible or liable for any loss, damage, delay or failure to act caused by any cause beyond our reasonable control.</p>
		<p>13.6  If any provision or part of a provision of these Terms and Conditions is unlawful, void or unenforceable, that provision or part of the provision is deemed severable from these Terms and Conditions and does not affect the validity and enforceability of any remaining provisions.</p>
		<p>13.7  There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms and Conditions or use of the Site or Services.</p>
		<p>13.8  Please note that these Terms and Conditions, their subject matter and their formation, are governed by Irish law. You and we both agree that the courts of The Republic of Ireland will have exclusive jurisdiction. If you have any complaint or wish to raise a dispute under these Terms and Conditions or otherwise in relation to the Site please follow this link http://ec.europa.eu/odr</p>
		<p>13.9  Except as stated under the Mobile Application section, a person who is not a party to these Terms and Conditions shall have no right under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of these Terms and Conditions.</p>
		<p>13.10  In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us by email at info@metaphoriclightbulb.org or by post to:</p>

		Sapiens Creations<br />
		Mullagh, Co. Clare<br />
		Ireland<br /><br />
	</>
}
