import React, {Component} from 'react'
import './legal.css'
import { Link } from "react-router-dom"

export default class LegalLinks extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		return (
			<div className={'legal-links-wrapper'}>
				<Link className={'legal-link'} to="/terms-and-conditions">Terms and Conditions</Link>
				<Link className={'legal-link'} to="/privacy-policy">Privacy Policy</Link>
				<Link className={'legal-link'} to="/acceptable-use">Acceptable Use</Link>
				<Link className={'legal-link'} to="/terms-and-conditions-of-supply">Terms and Conditions of Supply</Link>
			</div>
		)
	}
}