import React, {Component} from 'react'
import './legal.css'
import LegalLinks from "./LegalLinks"

export default class TermsAndConditionsOfSupply extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		return (
			<div>
				<h1 className={'terms-and-conditions-title'}>Terms And Conditions Of Supply</h1>
				<LegalLinks />
				<div className={'legal-wrapper'}>
					{this.textBlock()}
				</div>
			</div>
		)
	}

	textBlock = () => <>
		<div>
			Last modified: May 30, 2020
		</div>
		IMPORTANT: PLEASE READ THIS LICENSE CAREFULLY BEFORE USING THIS SOFTWARE.
		<h3>1. LICENSE</h3>
		By receiving, opening the file package, and/or using Metaphoric Lightbulb ("Software") containing this software, you agree that this End User User License Agreement (EULA) is a legally binding and valid contract and agree to be bound by it. You agree to abide by the intellectual property laws and all of the terms and conditions of this Agreement.
		Unless you have a different license agreement signed by Sapiens Creations your use of Metaphoric Lightbulb indicates your acceptance of this license agreement and warranty.
		Subject to the terms of this Agreement, Sapiens Creations grants to you a limited, non-exclusive, non-transferable license, without right to sub-license, to use Metaphoric Lightbulb in accordance with this Agreement and any other written agreement with Sapiens Creations. Sapiens Creations does not transfer the title of Metaphoric Lightbulb to you; the license granted to you is not a sale. This agreement is a binding legal agreement between Sapiens Creations and the purchasers or users of Metaphoric Lightbulb.
		If you do not agree to be bound by this agreement, remove Metaphoric Lightbulb from your device now and, if applicable, promptly return to Sapiens Creations by mail or destroy any copies of Metaphoric Lightbulb and related documentation and packaging in your possession.
		<h3>2. DISTRIBUTION</h3>
		Metaphoric Lightbulb and the license herein granted shall not be copied, shared, distributed, re-sold, offered for re-sale, transferred or sub-licensed in whole or in part except that you may make one copy for archive purposes only. For information about redistribution of Metaphoric Lightbulb contact Sapiens Creations.
		<h3>3. USER AGREEMENT</h3>
		<h4>3.1 Use</h4>
		Your license to use Metaphoric Lightbulb is limited to the number of licenses purchased by you. You shall not allow others to use, copy or evaluate copies of Metaphoric Lightbulb.
		<h4>3.2 Use Restrictions</h4>
		You shall use Metaphoric Lightbulb in compliance with all applicable laws and not for any unlawful purpose. Without limiting the foregoing, use, display or distribution of Metaphoric Lightbulb together with material that is pornographic, racist, vulgar, obscene, defamatory, libelous, abusive, promoting hatred, discriminating or displaying prejudice based on religion, ethnic heritage, race or sexual orientation is strictly prohibited.
		Each licensed copy of Metaphoric Lightbulb may be used by one end user (in the case of non-paying users) or one brand (in the case of subscribed companies). Use of Metaphoric Lightbulb means that you have loaded, installed, or run Metaphoric Lightbulb on a computer or similar device.
		You may make one copy of Metaphoric Lightbulb for backup purposes, providing you only have one copy installed on one computer being used by one person. Other users may not use your copy of Metaphoric Lightbulb . The assignment, sublicense, networking, sale, or distribution of copies of Metaphoric Lightbulb are strictly forbidden without the prior written consent of Sapiens Creations. It is a violation of this agreement to assign, sell, share, loan, rent, lease, borrow, network or transfer the use of Metaphoric Lightbulb. If any person other than yourself uses Metaphoric Lightbulb registered in your name, regardless of whether it is at the same time or different times, then this agreement is being violated and you are responsible for that violation!
		<h4>3.3 Copyright Restriction</h4>
		This Software contains copyrighted material, trade secrets and other proprietary material. You shall not, and shall not attempt to, modify, reverse engineer, disassemble or decompile Metaphoric Lightbulb. Nor can you create any derivative works or other works that are based upon or derived from Metaphoric Lightbulb in whole or in part.
		Sapiens Creations's name, logo and graphics file that represents Metaphoric Lightbulb shall not be used in any way to promote products developed with Metaphoric Lightbulb. Sapiens Creations retains sole and exclusive ownership of all right, title and interest in and to Metaphoric Lightbulb and all Intellectual Property rights relating thereto.
		Copyright law and international copyright treaty provisions protect all parts of Metaphoric Lightbulb, products and services. No program, code, part, image, audio sample, or text may be copied or used in any way by the user except as intended within the bounds of the single user program. All rights not expressly granted hereunder are reserved for Sapiens Creations.
		<h4>3.4 Limitation of Responsibility</h4>
		You will indemnify, hold harmless, and defend Sapiens Creations, its employees, agents and distributors against any and all claims, proceedings, demand and costs resulting from or in any way connected with your use of Sapiens Creations's Software.
		In no event (including, without limitation, in the event of negligence) will Sapiens Creations, its employees, agents or distributors be liable for any consequential, incidental, indirect, special or punitive damages whatsoever (including, without limitation, damages for loss of profits, loss of use, business interruption, loss of information or data, or pecuniary loss), in connection with or arising out of or related to this Agreement, Metaphoric Lightbulb or the use or inability to use Metaphoric Lightbulb or the furnishing, performance or use of any other matters hereunder whether based upon contract, tort or any other theory including negligence.
		Sapiens Creations's entire liability, without exception, is limited to the customers' reimbursement of the purchase price of the latest paid Software subscription (if any) (maximum being the lesser of the amount paid by you and the suggested retail price as listed by Sapiens Creations) in exchange for the return of the product, all copies, registration papers and manuals, and all materials that constitute a transfer of license from the customer back to Sapiens Creations.
		<h4>3.5 Warranties</h4>
		Except as expressly stated in writing, Sapiens Creations makes no representation or warranties in respect of this Software and expressly excludes all other warranties, expressed or implied, oral or written, including, without limitation, any implied warranties of merchantable quality or fitness for a particular purpose.

		<h4>3.6 Governing Law</h4>
		This Agreement shall be governed by the law of the Ireland applicable therein. You hereby irrevocably attorn and submit to the non-exclusive jurisdiction of the courts of Ireland therefrom. If any provision shall be considered unlawful, void or otherwise unenforceable, then that provision shall be deemed severable from this License and not affect the validity and enforceability of any other provisions.
		<h4>3.7 Termination</h4>
		Any failure to comply with the terms and conditions of this Agreement will result in automatic and immediate termination of this license. Upon termination of this license granted herein for any reason, you agree to immediately cease use of Metaphoric Lightbulb and destroy all copies of Metaphoric Lightbulb supplied under this Agreement. The financial obligations incurred by you shall survive the expiration or termination of this license.
		<h3>4. DISCLAIMER OF WARRANTY</h3>
		THIS SOFTWARE AND THE ACCOMPANYING FILES ARE SOLD "AS IS" AND WITHOUT WARRANTIES AS TO PERFORMANCE OR MERCHANTABILITY OR ANY OTHER WARRANTIES WHETHER EXPRESSED OR IMPLIED. THIS DISCLAIMER CONCERNS ALL FILES GENERATED AND EDITED BY Metaphoric Lightbulb AS WELL.
		<h3>5. CONSENT OF USE OF DATA</h3>
		You agree that Sapiens Creations may collect and use information gathered in any manner as part of the product support services provided to you, if any, related to Metaphoric Lightbulb. Sapiens Creations may also use this information to provide notices to you which may be of use or interest to you.
	</>
}
