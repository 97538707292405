import React from 'react'
import Loader from "react-loader-spinner"
import Icons from "../Icons/Icons"
import './AdvButton.css'

/**
 * @param  {object} props
 * @param props.onClick
 * @param props.text
 * @param props.icon
 * @param props.selected
 * @param props.loading
 * @param props.extendClassNameString
 * @param props.fullWidth
 *
 */
const AdvButton = (
	{
		onClick=()=>{},
		text='',
		icon=null,
		iconReverse=false,
		selected,
		loading,
		extendClassNameString='',
		fullWidth,
		showExpandable=false,
		id='',
		extraOnClickAction=null,
		disabled=false
	}={}
) => {

	const renderMain = () => <button
		id={id}
		onClick={(e) => {
			if(!disabled) {
				extraOnClickAction && extraOnClickAction()
				onClick(e)
			}
		}}
		className={`
			adv-button 
			${fullWidth ? 'adv-button__full-width' : ''} 
			${icon ? 'adv-button__with-icon' : ''} 
			${selected ? 'adv-button__selected' : ''} 
			${disabled ? 'adv-button__disabled' : ''} 
			${extendClassNameString}
		 `}
	>
		{
			<>
				<div className={`adv-button__text ${showExpandable ? 'adv-button__text__expandable' : ''}`}>
					{text}
				</div>
				{
					loading && <Loader className={'adv-button-loading-state-loader'} type="ThreeDots" height={20} width={20} radius={50} color={'white'}/>
				}
			</>
		}
		{icon && <div className={'adv-button__icon'}>{icon}</div>}
		{showExpandable && <Icons name={'ExpandMore'} className={'adv-button-expandable'} />}
	</button>

	return renderMain()
}

export default AdvButton
