import './QuizPreview.css'

const QuizPreview = ({quizData}) => {
  const quizQuestionsNum = quizData?.questions?.length
  const quizDifficulty = quizData?.difficulty
  return <div className={'quiz-preview'}>
    <div className={'quiz-preview__title'}>
      {quizData?.topic}
    </div>
    <div className={'quiz-preview__description'}>
      {quizQuestionsNum}Q | {quizDifficulty}
    </div>
  </div>
}

export default QuizPreview