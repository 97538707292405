import React from 'react'
import Loader from "react-loader-spinner"
import {preferWebFriendlyImage} from "../../functions/detectWebPSupport"
import './Loading.css'

const Loading = () => <div className={`loading-container`}>
	<div className={'is-loading-image'} style={{backgroundImage: `url("${preferWebFriendlyImage('/logo-icon.png')}")`}} />
	<Loader
		className={'loading-loader'}
		type="Rings"
		color={'var(--brand-color-purple)'}
		height={40}
		width={40}
	/>
</div>

export default Loading
