import React, {useEffect} from 'react'
import './Footer.css'
import {useLocation} from "react-router-dom"

const Footer = () => {
	const location = useLocation()

	useEffect(() => {
		// Forces re-render on location change to re-evaluate whether to show footer
	}, [location])

	const renderMain = () => (
		<div className={'footer'}>
		</div>
	)

	return renderMain()
}

export default Footer
