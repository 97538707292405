import {backendAddress} from "./credentials"
import {getItem} from "./functions/localStorage"

const getUserAuthToken = () => getItem({name: 'userAuthToken'}) || window.userAuthToken || 0

const advanceGet = async ({location, errorCallback=()=>{}, extraHeaders={}}={}) => {
	const userAuthToken = getUserAuthToken()
	let result
	try {
		result = await fetch(
			`${backendAddress}/${location}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${userAuthToken}`,
					...extraHeaders
				}
			}
		)
	}
	catch(e) {
		console.warn(e)
		errorCallback()
	}
	if(result?.status !== 200) {
		console.warn(result?.status)
		errorCallback()
		return null
	}
	result = await result.json()
	return result
}

const advancePost = async ({location, parameters, errorCallback=()=>{}, method='POST', extraHeaders}={}) => {
	const userAuthToken = getUserAuthToken()
	let result
	try {
		result = await fetch(
			`${backendAddress}/${location}`,
			{
				method: method,
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${userAuthToken}`,
					...extraHeaders
				},
				body: JSON.stringify(parameters)
			}
		)
	}
	catch(e) {
		console.warn(e)
		errorCallback()
	}
	if(result?.status !== 200) {
		console.warn(result?.status)
		errorCallback()
		return null
	}
	result = await result.json()
	return result
}

export {
	advanceGet,
	advancePost
}
