import React, {useState} from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"
import { auth, db, ref, onValue, onAuthStateChanged } from './firebaseSetup'
import {setItem} from "./functions/localStorage"
import Loading from "./Components/Loading/Loading"
import Footer from './Components/Footer/Footer'
import NavBar from './Components/NavBar/NavBar'
import './App.css'
import AcceptableUse from './Pages/Public/Legal/AcceptableUse'
import TermsAndConditionsOfSupply from './Pages/Public/Legal/TermsAndConditionsOfSupply'
import PrivacyPolicy from './Pages/Public/Legal/PrivacyPolicy'
import TermsAndConditions from './Pages/Public/Legal/TermsAndConditions'
import Main from './Components/Main/Main'
import Quiz from './Components/Quiz/Quiz'

let lastUserObject = null

const App = () => {
  const [userOrNull, setUserOrNull] = useState(null)
  const [userData, setUserData] = useState(null)
  const [publicData, setPublicData] = useState({})
  const [showLoading, setShowLoading] = useState(true)

  const resetState = async () => {
    await setUserOrNull(null)
    await setUserData(null)
    await setPublicData({})
  }

  const renderMain = () => <>
    <NavBar
      userOrNull={userOrNull}
      userData={userData}
      publicData={publicData}
    />
    <section className={'main-page'}>
      {renderRoutes()}
    </section>
    <Footer userOrNull={userOrNull} userData={userData} />
  </>

  const getAllRoutes = () => {
    const result = [...publicRoutes(), ...authenticatedRoutes()]
    return result
  }

  const renderRoutes = () => <>
    <Routes>
      {
        getAllRoutes().map(
          r => <Route
            key={r.key}
            path={r.path}
            element={r.element}
          />
        )
      }
    </Routes>
  </>

  const publicRoutes = () => [
    {
      key: 'route-for-main',
      path: "/",
      element: <Main />
    },
    {
      key: 'route-for-main',
      path: "/q/:quizId",
      element: <Quiz />
    },
    {
      key: 'route-for-terms-and-conditions',
      path: "/terms-and-conditions",
      element: <TermsAndConditions/>
    },
    {
      key: 'route-for-privacy-policy',
      path: "/privacy-policy",
      element: <PrivacyPolicy/>
    },
    {
      key: 'route-for-terms-and-conditions-of-supply',
      path: "/terms-and-conditions-of-supply",
      element: <TermsAndConditionsOfSupply/>
    },
    {
      key: 'route-for-acceptable-use',
      path: "/acceptable-use",
      element: <AcceptableUse/>
    },
  ]

  const authenticatedRoutes = () => {
    if(userOrNull) {
      return []
    }
    else {
      return []
    }
  }

  const authStateChange = async user => {
    const setFirebaseUserDataInState = async snapshotValue => {
      let valueToSet = snapshotValue || {}
      await setUserData(valueToSet)
    }

    const saveReferenceToUserToken = async () => {
      let res = await user?.getIdToken()
      setItem({name: 'userAuthToken', value: res})
      window.userAuthToken = res
    }

    if(!user) {
      return resetState()
    }
    else if(JSON.stringify(user) !== JSON.stringify(userOrNull)) {
      saveReferenceToUserToken()
      await setUserOrNull(user)
      onValue(
        ref(db, `users/${user.uid}`),
        async function(snapshot) {
          const snapshotVal = snapshot.val()
          await setFirebaseUserDataInState(snapshotVal)
        }
      )
    }
  }

  onValue(
    ref(db, 'publicData'),
    async snapshot => {
      if(snapshot.val() && JSON.stringify(snapshot.val()) !== JSON.stringify(publicData)) {
        setShowLoading(false)
        setPublicData(snapshot.val())
      }
    }
  )

  const conditionallyCallAuthStateChange = user => {
    if(lastUserObject !== JSON.stringify(user)) {
      authStateChange(user)
    }
    lastUserObject = JSON.stringify(user)
  }

  onAuthStateChanged(
    auth, conditionallyCallAuthStateChange
  )

  return <Router>
    <div id={'app'} className={`app`}>
        {
          showLoading && <Loading />
        }
        {
          renderMain()
        }
    </div>
  </Router>
}

export default App
