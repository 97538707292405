import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {advanceGet} from '../../requests'
import {metaphoricLightbulbAPIKey} from '../../credentials'
import './Quiz.css'
import AdvButton from '../AdvButton/AdvButton'
import Icons from '../Icons/Icons'

const QuizQuestion = ({quizQuestion, currentQuizQuestion}) => {

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null)

  useEffect(
    () => {
      setSelectedOptionIndex(null)
    },
    [quizQuestion]
  )

  const handleOptionClick = (index) => {
    setSelectedOptionIndex(index)
  }

  return <div className={'quiz-question'}>
    {
      quizQuestion?.imageUrl && <div className={'quiz-question-image__container'}>
        <div className={`quiz-question-image ${currentQuizQuestion % 2 === 0 ? 'quiz-question-image__alt' : ''}`} style={{backgroundImage: `url("${quizQuestion?.imageUrl}")`}} />
      </div>
    }
    <h2>Q{currentQuizQuestion+1}: {quizQuestion.question}</h2>
    <div className={'quiz-options'}>
      {quizQuestion?.options.map((option, index) => (
        <button
          className={`quiz-option ${selectedOptionIndex === index ? (quizQuestion?.correctOption === index ? 'quiz-option__correct' : 'quiz-option__incorrect') : 'quiz-option__no-response'}`}
          key={index}
          onClick={() => handleOptionClick(index)}
        >
          {option}
        </button>
      ))}
    </div>
  </div>
}

const Quiz = ({quizId}) => {
  const [totalQuizQuestions, setTotalQuizQuestions] = useState(null)
  const [currentQuizQuestion, setCurrentQuizQuestion] = useState(null)
  const [loadedQuiz, setLoadedQuiz] = useState(null)
  const params = useParams()
  if(!quizId) {
    quizId = params.quizId
  }

  const navigate = useNavigate()

  const handlePressRandomQuiz = async () => {
    const result = await advanceGet({
      location: `metaphoric-lightbulb/quiz-random`,
      extraHeaders: {symbolDoorApiKey: metaphoricLightbulbAPIKey}
    })
    if(result[0] && result[0]?.quizId) {
      navigate(`/q/${result[0]?.quizId}`)
    }
  }

  const loadQuiz = async () => {
    const result = await advanceGet({
      location: `metaphoric-lightbulb/quiz/${quizId}`,
      extraHeaders: {symbolDoorApiKey: metaphoricLightbulbAPIKey}
    })
    if(result && result[0] && result[0].quizId) {
      setLoadedQuiz(result[0])
    }
  }

  useEffect(
    () => {
      loadQuiz()
    },
    [quizId]
  )

  useEffect(
    () => {
      if(loadedQuiz) {
        setCurrentQuizQuestion(0)
        setTotalQuizQuestions(loadedQuiz?.questions.length)
      }
    },
    [loadedQuiz]
  )

  return (
    <div className="question-container-outer">
      <div className="question-container">
        <h1>{loadedQuiz?.topic}</h1>
        {
          loadedQuiz?.difficulty &&
          <div className={'question-container__info'}>{loadedQuiz?.questions.length}Q | {loadedQuiz?.difficulty}</div>
        }
        {
          loadedQuiz?.questions && loadedQuiz?.questions
            .filter((question, i) => i === currentQuizQuestion)
            .map(
            question => <QuizQuestion currentQuizQuestion={currentQuizQuestion} quizQuestion={question} />
          )
        }
      </div>
      <div className={'quiz-navigation'}>
        <Icons
          name={'Left'}
          classNameExtra={`quiz-navigation-icon ${currentQuizQuestion === 0 ? 'quiz-navigation-icon__disabled' : ''}`}
          onClick={currentQuizQuestion === 0 ? () => {} : () => setCurrentQuizQuestion(currentQuizQuestion - 1)}
        />
        <Icons
          name={'Right'}
          classNameExtra={`quiz-navigation-icon ${currentQuizQuestion === totalQuizQuestions - 1 ? 'quiz-navigation-icon__disabled' : ''}`}
          onClick={currentQuizQuestion === totalQuizQuestions - 1 ? () => {} : () => setCurrentQuizQuestion(currentQuizQuestion + 1)}
        />
      </div>
      <AdvButton
        onClick={handlePressRandomQuiz}
        text={'Random Quiz'}
        extendClassNameString={'question-container-outer__random-quiz-button question-container-outer__random-quiz-button__end'}
      />
    </div>
  )
}

export default Quiz