import {initializeApp} from "firebase/app"
import {firebaseConfig} from "./credentials"
import {
	getAuth,
	onAuthStateChanged,
	GoogleAuthProvider,
	fetchSignInMethodsForEmail,
	signInWithPopup,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	createUserWithEmailAndPassword,
	signOut
} from "firebase/auth"
import {getDatabase, ref, set, onValue, off} from "firebase/database"

initializeApp(firebaseConfig)
const auth = getAuth()
const db = getDatabase()

export {
	auth,
	onAuthStateChanged,
	GoogleAuthProvider,
	fetchSignInMethodsForEmail,
	signInWithPopup,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	signOut,
	createUserWithEmailAndPassword,
	getDatabase,
	db,
	ref,
	set,
	onValue,
	off
}
