import './NavBar.css'
import Search from '../Search/Search'

const NavBar = () => {
  return <nav className={'nav-bar'}>
    <img className={'nav-logo'} src={'/logo-icon.png'} />
    <div className={'search-container'}>
      <Search />
    </div>
  </nav>
}

export default NavBar