const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
	// [::1] is the IPv6 localhost address.
	window.location.hostname === '[::1]' ||
	// 127.0.0.1/8 is considered localhost for IPv4.
	window.location.hostname.match(
		/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
	)
)

module.exports = {
	metaphoricLightbulbAPIKey: process.env.REACT_APP_METAPHORIC_LIGHTBULB_PUBLIC_API_KEY,
	frontendAddress: isLocalhost
		? 'http://localhost:3000'
		: 'https://metaphoriclightbulb.org',
	backendAddress: isLocalhost
		? 'http://localhost:5001/symboldoor-e656e/us-central1/mainF'
		: 'https://us-central1-symboldoor-e656e.cloudfunctions.net/mainF',
	firebaseConfig: {
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		authDomain: "metaphoric-lightbulb-27b8f.firebaseapp.com",
		databaseURL: "https://metaphoric-lightbulb-27b8f-default-rtdb.firebaseio.com",
		projectId: "metaphoric-lightbulb-27b8f",
		storageBucket: "metaphoric-lightbulb-27b8f.appspot.com",
		messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
		measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
	},
}
